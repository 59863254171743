
import { Component, Vue, Watch } from 'vue-property-decorator';
import { app } from './outages/app'
import { Distributor, distributorStore } from './outages/distributor-store';
import Banner from "./components/Banner.vue";

import DistributorModal from './components/DistributorModal.vue'
import Map from './components/Map.vue';
import MenuButton from './components/MenuButton.vue'
import SideBar from './components/SideBar.vue';


@Component({
    components: {
        DistributorModal,
        Map,
        MenuButton,
        SideBar,
        Banner
    },
})
export default class App extends Vue
{
    searchValue = '';
    matchedDistributors: Distributor[] = [];

    constructor()
    {
        super();
        app.onSearchValueChanged = this.onSearchValueChange;
    }

    async onSearchValueChange(newVal: string)
    {
        if (newVal)
        {
            this.searchValue = newVal;
            this.matchedDistributors = await distributorStore.getMatchedDistributors(this.searchValue);
        }
    }
}
