
import { Component, Vue } from 'vue-property-decorator';
import { outageStore, Outage } from '../outages/outage-store';
import { app } from '../outages/app';
import dayjs from 'dayjs';

@Component
export default class SideBar extends Vue
{
    isExpanded = false;
    totalActiveOutages = 0;
    totalAffectedCustomers = 0;
    lastUpdatedAt = '';

    filterOptions = {
        unplanned: true,
        planned: true,
        restored: true
    };

    constructor()
    {
        super();
        app.onOutageDataLoaded = this.getStatusInfo;
    }

    dayjs(dateTime: any): any
    {
        return dayjs(dateTime);
    }

    getStatusInfo(): void
    {
        const outageTotals = outageStore.getActiveOutageTotals();

        if (outageTotals)
        {
            this.totalActiveOutages = outageTotals.TotalActiveOutages;
            this.totalAffectedCustomers = outageTotals.TotalAffectedCustomers;
        }

        this.lastUpdatedAt = dayjs(outageStore.getAllOutages().map(x => x.FeedReceivedTime).sort().reverse()[0]).format('h:mm A');
    }

    updateOutageFilters()
    {
        outageStore.setFilter(outage =>
            (!outage.IsResolved && outage.Type === 'Unplanned' && this.filterOptions.unplanned) // active outages, unplanned
            || (!outage.IsResolved && outage.Type === 'Planned' && this.filterOptions.planned) // active outages, planned
            || (outage.IsResolved && this.filterOptions.restored) // resolved outages, both unplanned and planned
        );

        app.addMarkerClusters();
    }
}
