
import { warn } from 'vue-class-component/lib/util';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Distributor } from '../outages/distributor-store';

@Component
export default class DistributorModal extends Vue
{
    @Prop() suburbSearch!: string;
    @Prop() distributors!: Distributor[];

    isSuburbAppearingAgain(index: number): boolean
    {
        return index > 0
            && this.distributors[index - 1].Locality === this.distributors[index].Locality;
    }
}

