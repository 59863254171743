import dayjs from 'dayjs';

export interface Outage
{
    Cause: any;
    EstimatedRestorationTime: string;
    EventId: any;
    Status: string;
    ImpactedArea: any[][];
    ImpactedCustomers: number;
    ImpactedSuburbs: any[];
    ImpactedAreaGeoJson: any;
    IsResolved: boolean;
    IsShowOutage: boolean;
    LastUpdatedTime: string;
    Location: {
        Latitude: number;
        Longitude: number;
    };
    RestorationTime: string;
    StartTime: string;
    Type: string;
    History: Outage[];
    PlannedStartTime: string;
    PlannedEndTime: string;
    FeedReceivedTime: string;
}

export interface OutageTotal
{
    TotalActiveOutages: number;
    TotalAffectedCustomers: number;
}

export class OutageStore
{
    state: Outage[];
    filter: OutageFilterPredicate<Outage>;

    constructor()
    {
        this.state = [];
        this.filter = (item: Outage) => true;
    }

    pushOutages(outages: Outage[])
    {
        this.state = outages;
    }

    getOutages(): Outage[]
    {
        const histories = this.state.map((outage: Outage) =>
        {
            return this.mapValuesFromParentToHistory(outage)
        }).flat()
            .filter(x => x != null);

        return this.state
            .concat(histories)
            .filter(x => x.ImpactedCustomers != 1)
            .filter(x => x.Type == 'Planned' || x.IsShowOutage)
            .filter(x => !x.IsResolved
                || (x.RestorationTime && dayjs().diff(dayjs(x.RestorationTime), 'hour') <= 24)) // Always filter out resolved outages past 24 hours ago.
            .filter(x => this.filter(x));
    }

    getAllOutages(): Outage[]
    {
        return this.state;
    }

    getActiveOutageTotals(): OutageTotal
    {
        const allOutages = this.getOutages().filter(x => !x.IsResolved
            && (x.RestorationTime === null || x.RestorationTime === undefined)
            || (x.Type == 'Unplanned' && x.IsShowOutage && !x.IsResolved)
        );

        let totalAffectedCustomers = 0;
        if (allOutages.length > 0)
        {
            totalAffectedCustomers = allOutages.map(x => x.ImpactedCustomers).reduce((total, num) => total + num);
        }

        return { TotalActiveOutages: allOutages.length, TotalAffectedCustomers: totalAffectedCustomers };
    }

    setFilter(outageFilter: OutageFilterPredicate<Outage>)
    {
        this.filter = outageFilter;
    }

    mapValuesFromParentToHistory(outage: Outage): Outage[]
    {
        return outage.History?.map((outageHistory: Outage) =>
        {
            outageHistory.EventId = outage.EventId;
            outageHistory.Type = outage.Type;
            outageHistory.IsResolved = true;
            outageHistory.StartTime = outageHistory.ImpactedAreaGeoJson?.properties.startTime;
            outageHistory.RestorationTime = outageHistory.ImpactedAreaGeoJson?.properties.restoreTime;
            outageHistory.ImpactedCustomers = outageHistory.ImpactedAreaGeoJson?.properties.customersOff;
            outageHistory.Cause = outage.Cause;
            outageHistory.LastUpdatedTime = outage.LastUpdatedTime;
            outageHistory.IsShowOutage = outage.Status != 'Cancelled';
            return outageHistory;
        });
    }
}

export type OutageFilterPredicate<T> = (item: T) => boolean;

export const outageStore = new OutageStore();