
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { app } from "../outages/app";
import { Outage } from '../outages/outage-store';
import dayjs from 'dayjs';

@Component
export default class DistributorModal extends Vue
{
    @Prop() outage!: Outage;

    @Emit('close')
    close()
    {
        return false;
    }

    formatDate(dateTime: any): any
    {
        return dateTime ? dayjs(dateTime).format('DD MMM YYYY h:mm A') : '';
    }
}
