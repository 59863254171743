import { attachAddressSearch } from './location-search'
import { Outage, outageStore } from './outage-store'
declare const MarkerClusterer: any;

export type OutageDataLoaded = () => void;
export type SearchValueChanged = (searchValue: string) => void;
export type MarkerClicked = (outage: Outage) => void;

class AppGlobal
{
    map: google.maps.Map<Element> | null;
    markers: google.maps.Marker[] = [];
    markerCluster: any = null;
    impactedAreas: google.maps.Polygon[] = [];
    infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();

    victoriaBounds: google.maps.LatLngBoundsLiteral = {
        north: -33.788279,
        south: -39.436193,
        west: 140.756836,
        east: 150.139160,
    };

    onOutageDataLoaded!: OutageDataLoaded;

    onSearchValueChanged!: SearchValueChanged;
    private _searchValue = '';
    public get searchValue()
    {
        return this._searchValue;
    }
    public set searchValue(value)
    {
        this._searchValue = value;
        if (this.onSearchValueChanged)
        {
            this.onSearchValueChanged(value);
        }
    }

    onMarkerClicked!: MarkerClicked;

    constructor()
    {
        this.map = null;
    }

    initMap = () =>
    {
        console.log('init map called');

        this.map = new google.maps.Map(document.getElementById("map") as Element, {
            center: { lat: -37.6690123, lng: 144.8410273 },
            zoom: 11,
            minZoom: 8,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT,
            },
            streetViewControl: false,
            gestureHandling: 'greedy',
            restriction: {
                latLngBounds: this.victoriaBounds
            },
            clickableIcons: false
        });

        this.infoWindow = new google.maps.InfoWindow();

        this.loadBoundaryForJemena();
        attachAddressSearch();
        this.getOutageData().then(() =>
        {
            if (this.onOutageDataLoaded)
            {
                this.onOutageDataLoaded();
            }
            this.addMarkerClusters();
        });
        this.map.fitBounds(this.getBoundaryLatLongForJemena());
    }

    clearMap = () =>
    {
        if (this.markers)
        {
            if (this.markerCluster)
            {
                this.markerCluster.removeMarkers(this.markers);
            }

            this.markers.forEach(x => x.setMap(null));
            this.markers = [];
        }

        if (this.impactedAreas)
        {
            this.impactedAreas.forEach(x => x.setMap(null));
        }
    }

    loadBoundaryForJemena = () =>
    {
        this.map?.data.loadGeoJson('boundary/boundary.json');
        this.map?.data.setStyle({ strokeColor: 'rgb(23, 53, 131)' });
    }

    getOutageData = () =>
    {
        return fetch(`${process.env.VUE_APP_OUTAGES_DATA_URL}?t=${new Date().getTime()}`, { credentials: 'include' }
        ).then(response => response.json())
            .then(data =>
            {
                console.dir(data);
                outageStore.pushOutages(data);
            });
    }

    centerOnOutage = (outage: Outage) =>
    {
        this.map?.setCenter(new google.maps.LatLng(outage.Location.Latitude, outage.Location.Longitude));
        //this.map?.setZoom(13);
    }

    addMarkerClusters = () =>
    {
        const data = outageStore.getOutages();

        this.clearMap();

        data.map((outage: Outage) =>
        {
            if (outage && outage.Location && outage.Location?.Latitude && outage.Location?.Longitude)
            {
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(outage.Location.Latitude, outage.Location.Longitude),
                    icon: {
                        url: outage.IsResolved ? "images/restored.png" : (outage.Type === 'Unplanned' ? "images/unplanned_outage.png" : "images/planned_outage.png"), // url
                        scaledSize: new google.maps.Size(35, 35), // scaled size
                        origin: new google.maps.Point(0, 0), // origin
                        anchor: new google.maps.Point(17, 17) // anchor
                    }
                });

                marker.addListener('click', () =>
                {
                    if (this.onMarkerClicked)
                    {
                        this.onMarkerClicked(outage);
                        this.centerOnOutage(outage);
                    }
                });
                this.markers.push(marker)
            }
        });

        console.dir(this.markers);
        this.markerCluster = new MarkerClusterer(this.map, this.markers, { imagePath: '/images/clusterer/m', maxZoom: 14 });

        data.map((outage: Outage) =>
        {
            if (outage && outage.ImpactedArea && outage.ImpactedArea.length > 0)
            {
                const shadedColor = outage.IsResolved ? '#43b579' : (outage.Type === 'Planned' ? '#173583' : '#F58220');
                const shadedArea = new google.maps.Polygon({
                    paths: outage.ImpactedArea[0].map(position => new google.maps.LatLng(position.Latitude, position.Longitude)),
                    strokeColor: shadedColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: shadedColor,
                    fillOpacity: 0.35,
                });
                this.impactedAreas.push(shadedArea);
                shadedArea.setMap(this.map);
            }
        });
    }

    getBoundaryLatLongForJemena()
    {
        return new google.maps.LatLngBounds(
            new google.maps.LatLng(-37.886862746765416, 144.4265860546875),
            new google.maps.LatLng(-37.44987352392226, 145.14344396484375));
    }


}

export const app = new AppGlobal();


//
// Go to my location
//

function updateLocation(position: Position)
{
    console.log(position);
    app.map?.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
    app.map?.setZoom(13);
}

function handleLocationError(error: PositionError): void
{
    console.dir(error);
}

window.document.getElementById('gotToMyLocationButton')?.addEventListener('click', () =>
{
    navigator.geolocation.getCurrentPosition(updateLocation, handleLocationError, { enableHighAccuracy: true });
});

