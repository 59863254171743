
import { Component, Vue } from "vue-property-decorator";
import { NotificationMessages } from "../outages/notification-messages";
import { notificationStore } from "../outages/notification-store";

@Component
export default class Banner extends Vue
{
    htmlContent!: string;
    isActive = false;

    async created()
    {
        const notificationMessages = new NotificationMessages();
        await notificationMessages.getOutageData();
        this.htmlContent = notificationStore
            .getNotificationMessage().content.filter(x => x.Type === 'poweroutage').map(b => b.Content).join(" ");

        if (this.htmlContent)
        {
            this.isActive = true;
        }
    }

    iconCloseClick()
    {
        this.isActive = !this.isActive;
    }
}
