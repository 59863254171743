
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MenuButton extends Vue
{
    toggle()
    {
        const element = document.getElementById("sidebar-column");
        const button = document.getElementById("side-menu-btn");
        const mainContent = document.getElementById("main-content");
        if (element && button && mainContent)
        {
            if (element.classList.contains("d-none"))
            {
                element.classList.remove("d-none");
                button.classList.remove("in");
                button.classList.add("offset-4");
                button.classList.add("offset-sm-3");
                mainContent.classList.remove("col-12");
                mainContent.classList.add("col-sm-7");
                mainContent.classList.add("col-4");
            } else
            {
                element.classList.add("d-none");
                button.classList.add("in");
                button.classList.remove("offset-4");
                button.classList.remove("offset-sm-3");
                mainContent.classList.add("col-12");
                mainContent.classList.remove("col-sm-7");
                mainContent.classList.remove("col-4");
            }
        }
    }
}
