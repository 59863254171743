import { NotificationMessages } from './notification-messages';

export interface NotificationMessage
{
    content: NotificationMessageContent[];
}
export interface NotificationMessageContent
{
    Content: string;
    Type: string;
    DisplayStartTime: string;
    DisplayEndTime: string;
}
export class NotificationStore
{
    state: NotificationMessage;

    constructor()
    {
        this.state = {
            content: []
        };
    }

    setNotificationMessage(notificationMessage: NotificationMessage)
    {
        this.state = notificationMessage;
    }

    getNotificationMessage(): NotificationMessage
    {
        return this.state;
    }
}

export const notificationStore = new NotificationStore();