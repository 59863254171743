export interface Distributor
{
    Company: string;
    Locality: string;
    Postcode: string | any[];
    Phone: string;
    Website: string;
}

export class DistributorStore
{
    state: Distributor[];

    constructor()
    {
        this.state = [];
    }

    async getMatchedDistributors(suburbSearch: string): Promise<Distributor[]>
    {
        if (this.state.length === 0)
        {
            await this.fetchDistributors();
        }

        const matchedDistributors = this.state.filter((distributor: Distributor) =>
        {
            return distributor.Locality.toLowerCase().indexOf(suburbSearch.toLowerCase()) !== -1 ||
                distributor.Postcode.indexOf(suburbSearch.toLowerCase()) !== -1;
        }).sort((d1, d2) =>
        {
            if (d1.Locality === d2.Locality)
            {
                return d1.Company.localeCompare(d2.Company);
            }
            else
            {
                return d1.Locality.localeCompare(d2.Locality);
            }
        });

        if (matchedDistributors.length > 0)
        {
            let havingNonJemena = false;

            matchedDistributors.forEach((current: any) =>
            {
                console.dir(current);
                if (current.Company !== 'Jemena')
                {
                    havingNonJemena = true;
                }
            });

            if (!havingNonJemena)
            {
                return [];
            }
            else
            {
                return matchedDistributors;
            }
        }
        else
        {
            console.log('No matched found for: ' + suburbSearch);
            return [];
        }
    }

    private async fetchDistributors()
    {
        const response = await fetch('boundary/electricitydistributors.json');
        const data = await response.json();
        this.state = data[0].Table;
    }
}

export const distributorStore = new DistributorStore();