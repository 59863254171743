import { app } from './app';

export function attachAddressSearch()
{


    function handlePlaceChanged(autocomplete: google.maps.places.Autocomplete, addressFieldId: string)
    {
        const googleAutocompleteResult = autocomplete.getPlace();
        if (googleAutocompleteResult && googleAutocompleteResult.formatted_address)
        {
            console.log(googleAutocompleteResult.formatted_address);
            console.dir(googleAutocompleteResult?.geometry?.location);
            if (googleAutocompleteResult?.geometry?.viewport)
            {
                app.map?.fitBounds(googleAutocompleteResult.geometry.viewport);
            } else
            {
                if (googleAutocompleteResult?.geometry?.location)
                {
                    app.map?.setCenter(googleAutocompleteResult?.geometry?.location);
                    app.map?.setZoom(11);
                }
            }

            const postcode = googleAutocompleteResult?.address_components?.find(obj => obj.types.find(c => c === 'postal_code'));
            const suburb = googleAutocompleteResult?.address_components?.find(obj => obj.types.find(c => c === 'locality'));
            if (postcode || suburb)
            {
                console.log('post code found: ')
                console.dir(postcode?.long_name)
                console.log('suburb found: ')
                console.dir(suburb?.long_name)
                const searchValue = suburb?.long_name || postcode?.long_name
                app.searchValue = searchValue ?? '';
            } else
            {
                console.log('ERROR: Unable to find postcode for the google result');
            }

        } else
        {
            console.log('ERROR: There was an error with getting a result from google places');
        }
    }

    function initAutocomplete(addressFieldId: string)
    {
        const jemenaBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(-37.886862746765416, 144.4265860546875),
            new google.maps.LatLng(-37.44987352392226, 145.14344396484375));

        console.log(addressFieldId);
        console.dir(document.getElementById(addressFieldId));

        const autocomplete = new google.maps.places.Autocomplete(
            (document.getElementById(addressFieldId) as HTMLInputElement), {
            bounds: jemenaBounds,
            types: ['geocode'],
            componentRestrictions: {
                country: 'AU'
            }
        });
        autocomplete.addListener('place_changed', () =>
        {
            handlePlaceChanged(autocomplete, addressFieldId);
        });
    }

    initAutocomplete('address-search');
}